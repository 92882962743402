import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { options } from "../components/parser"
import parse from "html-react-parser"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  TextCard,
  SoliWhite,
  SoliCard,
  Text,
  BigText,
  Anchor,
  device,
  ProjektLink,
} from "../components/styles"
import { FlowerFloat } from "../components/headerlinks"
import Arrow from "../images/arrow.inline.svg"

const ProjectLinkFloat = styled.a`
  margin: 0.2rem;
  padding: 0 0rem 0 0rem;
  display: inline-block;
  height: auto;
  width: 50%;
  float: right;
`
const DeinProjectLinkFloat = styled(Link)`
  margin: 0.2rem;
  padding: 0 0rem 0 0rem;
  display: inline-block;
  height: auto;
  width: 50%;
  float: right;
`
const SoliProjekteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--solifont);
  font-size: 2rem;
  color: black;
  font-weight: 500;
  line-height: 1;
  padding: 0.5rem;
  margin: 0;
  text-decoration: none;
  border: 2px solid black;
  background: var(--bgcolortrans);
  width: 100%;
`
const ProjektImg = styled(GatsbyImage)`
  margin: 0;
  padding: 2px;
  vertical-align: middle;
  border: 2px solid black;
  background-color: white;
  &:hover {
    padding: 15px;
  }
`
const ArrowUp = styled(Arrow)`
  display: inline-block;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  margin: 0;
  height: 40px;
  width: auto;
`
const ProjektLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  @${device.tablet} {
    align-items: flex-start;
    margin: 0 0 0 0;
    position: relative;
  }
`

const LeftSide = ({ data }) => (
  <ProjektLinks>
    <Anchor id={"top"} />

    {data.edges.map(
      (
        {
          node: {
            id,
            frontmatter: { title },
          },
        },
        index
      ) => (
        <ProjektLink key={id} href={"#" + title}>
          #{title}
        </ProjektLink>
      )
    )}
  </ProjektLinks>
)

const RightSide = ({
  data: {
    id,
    html,
    frontmatter: { title, bild, bildtext },
  },
}) => (
  <SoliCard id={id}>
    <SoliWhite>
      {title} <FlowerFloat />
    </SoliWhite>

    <BigText>{parse(html, options)}</BigText>
  </SoliCard>
)

const Projekte = ({ data: { Projekte, Projekterechts, ProjektBilder } }) => {
  const FindeBild = (bild) =>
    ProjektBilder.edges.find((g) => g.node.childImageSharp.parent.name === bild)
      .node.childImageSharp.gatsbyImageData
  return (
    <Layout
      leftside={<LeftSide data={Projekte} />}
      rightside={<RightSide data={Projekterechts} />}
    >
      {Projekte.edges.map(
        (
          {
            node: {
              id,
              html,
              frontmatter: { title, link, bild, bildtext },
            },
          },
          index
        ) => {
          return title === "Dein Projekt" ? (
            <TextCard key={index}>
              <Anchor id={title} />
              <SoliProjekteHeader>
                {title}
                <a href="#top" aria-label="nach oben">
                  <ArrowUp />
                </a>
              </SoliProjekteHeader>
              <Text>
                <DeinProjectLinkFloat to="/verein/">
                  <GatsbyImage image={FindeBild(bild)} alt={bildtext} />
                  Weitere Infos
                </DeinProjectLinkFloat>
                {parse(html, options)}
              </Text>
            </TextCard>
          ) : (
            <TextCard key={id}>
              <Anchor id={title} />
              <SoliProjekteHeader>
                {title}
                <a href="#top" aria-label="nach oben">
                  <ArrowUp />
                </a>
              </SoliProjekteHeader>
              <Text>
                <ProjectLinkFloat href={link}>
                  <ProjektImg image={FindeBild(bild)} alt={bildtext} />
                  zur Webseite
                </ProjectLinkFloat>
                {parse(html, options)}
              </Text>
            </TextCard>
          )
        }
      )}
    </Layout>
  )
}

export default Projekte

export const query = graphql`
  query ProjektQuery {
    Projekte: allMarkdownRemark(
      filter: {
        frontmatter: { online: { eq: "ja" }, location: { eq: "Projekte" } }
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            online
            title
            location
            link
            bild
            bildtext
          }
        }
      }
    }
    Projekterechts: markdownRemark(
      frontmatter: { location: { eq: "Projekterechts" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    ProjektBilder: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "SoliProjekte" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 400)
            parent {
              ... on File {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
